import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '60px',
    paddingLeft: 12,
    display: "flex",
    alignContent: 'center',
    paddingRight: 12
  },
  link: {
    fontSize: 20,
    color: '#ffffff',
    marginRight: 8,
    marginLeft: 8
  },
  mobLink: {
    display: "block",
    fontSize: 20,
    color: '#ffffff',
    marginTop: 10,
    marginBottom: 10
  },
  tabletLink: {
    fontSize: 16,
    color: '#ffffff',
  },
  menuButton: {
    marginRight: theme.spacing(4),
  },
  openedMenu: {
    border: 'solid 1px #ffffff',
    borderRadius: '5px'
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1,
  },
  search: {
    color: 'white',
    borderBottom: '1px solid white',
  },
}));