import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    mapColumn: {
        order: 1,
        [theme.breakpoints.down('sm')]: {
            order: 2
        },
    },
    filtersColumn: {
        order: 2,
        [theme.breakpoints.down('sm')]: {
            order: 1
        },
    },
}));