import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Translation_UA from './dictionaries/ua_dictionary';
import Translation_RU from './dictionaries/ru_dictionary';
import Translation_EN from './dictionaries/en_dictionary';

i18n
    .use(initReactI18next)
    .init({
      resources: {
        ua: {
          translations: Translation_UA
        },
        ru: {
          translations: Translation_RU
        },
        en: {
          translations: Translation_EN
        },
      },
      fallbackLng: "ua",
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: '.',
      interpolation: {
        escapeValue: false
      }
    });

export default i18n;
