import React from 'react';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import './styles.scss'

export default function Contacts() {
  const { t } = useTranslation()
  return (
    <div>
      <Typography variant="h4" color="primary" className="contacts-spacing_m-b-1">{t('contacts-page.title')}</Typography>
      <Typography variant="h6" color="primary">{t('contacts-page.email.title')}</Typography>
      <Typography variant="subtitle1" className="contacts-spacing_m-b-1">{t('contacts-page.email.content')}</Typography>
      <Typography variant="h6" color="primary">{t('contacts-page.phone.title')}</Typography>
      <Typography variant="subtitle1">{t('contacts-page.phone.phone1')}</Typography>
      <Typography variant="subtitle1">{t('contacts-page.phone.phone2')}</Typography>
    </div>
  )
}