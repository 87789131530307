import Typography from "@mui/material/Typography";
import {withTranslation} from "react-i18next";
import React from 'react';

function CoachUI(props) {

    function getValue(data) {
        return data ? data : '-'
    }

    function getLocation(userData) {
        let locations = []
        let usersLocation = userData ? userData.locations : null
        if (usersLocation != null && !usersLocation.empty) {
            usersLocation.map((l) => locations.push(<Typography key={l.name}>{l.name}</Typography>))
        }
        return (
            <div>
                <Typography variant="subtitle1">{locations.length ? locations : '-'}</Typography>
            </div>
        )
    }

    function getField(userData, value) {
        return (
            <Typography variant="subtitle1">
                {userData ? getValue(userData[value]) : '-'}
            </Typography>
        )
    }

    const {t, userData} = props
    return (
        <div>
            <Typography variant="h6">{t('coach-page.name')}</Typography>
            {getField(userData, 'firstName')}
            <Typography variant="h6">{t('coach-page.surname')}</Typography>
            {getField(userData, 'lastName')}
            <Typography variant="h6">{t('coach-page.userName')}</Typography>
            {getField(userData, 'userName')}
            <Typography variant="h6">{t('coach-page.locations')}</Typography>
            {getLocation(userData)}
            <Typography variant="h6">{t('coach-page.active-athlete-count')}</Typography>
            {getField(userData, 'activeAthleteCount')}
        </div>
    )
}

export default withTranslation()(CoachUI);
