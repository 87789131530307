import React from 'react';
import { Grid, Paper } from '@mui/material';
import Filters from "../Filters/Filters";
import {useStyles} from "./styles";
import Map from "./Map/Map";

export default function FindCoachMap() {
  const classes = useStyles();

  return (
      <>
        <Grid item xs={12} md={9} className={classes.mapColumn}>
          <Map />
        </Grid>
        <Grid item xs={12} md={3} className={classes.filtersColumn}>
          <Paper>
            <Filters/>
          </Paper>
        </Grid>
      </>
  );
}