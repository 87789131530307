import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { createHashHistory  } from "history";
import map from './reducers/map';
import coachReducer from './coachSlice'
import locationReducer from './locationSlice'
import filterReducer from './filterSlice'

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createHashHistory() });

export const store = configureStore({
    reducer: combineReducers({
        router: routerReducer,
        locationReducer,
        map,
        coachReducer,
        filterReducer
    }),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);