import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FindCoachMap from "./findcoach-ui/FindCoachMap/FindCoachMap";
// import {Container, CssBaseline, Grid} from "@material-ui/core";
import { Container, CssBaseline, Grid } from "@mui/material";
//TODO: provide .scss styles where it is possible
import {useStyles} from "./styles";
import {Route, Routes} from "react-router-dom";
import Footer from "./findcoach-ui/Footer/Footer";
import ApplicationBar from "./findcoach-ui/Navigation/ApplicationBar/ApplicationBar";
import Home from "./findcoach-ui/Home/Home";
import Contacts from "./findcoach-ui/Contacts/Contacts";
import Coach from "./findcoach-ui/Coaches/Coach/Coach";



function App() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline/>

            <ApplicationBar />
            <>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3} className={classes.wrapper}>
                            <Routes>
                                <Route index path="/" element={<Home/>}/>
                                <Route path="/map" element={<FindCoachMap/>}/>
                                <Route path="/contacts" element={<Contacts/>}/>
                                <Route
                                    path='/user/:id'
                                    element={<Coach/>}
                                />
                            </Routes>
                        </Grid>
                        <Footer/>
                    </Container>
                </main>
            </>
        </div>
    );
}

export default App;
