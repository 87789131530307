import {createSlice} from '@reduxjs/toolkit'
import {MAP_FILTER_TYPES} from '../constants/constants';

const {LOCATIONS_MAP_TYPE, COACHES_MAP_TYPE} = MAP_FILTER_TYPES

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        mapType: LOCATIONS_MAP_TYPE,
    },
    reducers: {
        setLocationsMap: (state)=> {
            state.mapType = LOCATIONS_MAP_TYPE;
        },
        setCoachesMap: (state) => {
            state.mapType = COACHES_MAP_TYPE;
        },
    },
})

export const {setLocationsMap, setCoachesMap} = filterSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectMapType = (state) => {
    return state.mapType
}
export default filterSlice.reducer
