import React from 'react'
import {Box, Link, Typography} from "@mui/material";
import {LINKS} from "../../constants/constants";
import {useTranslation} from "react-i18next";

function Copyright ({t}) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('footer.copyright')}{' '}
      <Link color="inherit" target="_blank" href={LINKS.MAIN_APP}>
        {t('application-name')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer () {
  const {t} = useTranslation()
  return (
    <Box pt={4}>
      <Copyright t={t} />
      <Typography variant="body2" color="textSecondary" align="center">
        <Link href={LINKS.PRIVACY_POLICY}>Privacy policy</Link>&nbsp;
        <Link href={LINKS.TERMS_OF_SERVICE}>Terms of service</Link>
      </Typography>
    </Box>
  )
}