import ListItemIcon from "@mui/material/ListItemIcon";
import {ReactComponent as AboutFindCoachIcon} from "../assets/icons/about-findcoach-gray.svg";
import CoachModal from "../findcoach-ui/Coaches/CoachModal";
import React from "react";

import './styles.css';
import vector from './vector.svg';
import {v4 as uuidv4} from "uuid";


export const denormalizeMarkers = (markers) => {
    let shallowMarkers = JSON.parse(JSON.stringify(markers));
    return shallowMarkers.map((marker) => {
        if (!marker.name) {
            marker.name = `${marker.firstName} ${marker.lastName}`
        }
        return marker
    })
}

export const getMarkersList = ({markers}) => {
    const markersList = {}
    let shallowMarkers = JSON.parse(JSON.stringify(markers));
    shallowMarkers.forEach((marker) => {
        const {x, y} = marker.coordinate
        const key = `${x}${y}`
        if (!markersList[key]) {
            markersList[key] = marker
            markersList[key].users = []
            markersList[key].users.push({name: marker.name, id: marker.id})
        } else {
            markersList[key].users.push({name: marker.name, id: marker.id})
        }
    })

    return Object.values(markersList)
}

const locationContent = ({name, address, phone, site, description}) => {

    return (
        <div key={uuidv4()}>
            <h5 className='popup__title' key={uuidv4()}>{name}</h5>
            <ul className="popup__menu" key={uuidv4()}>
                {address &&
                    <li className='item address' key={uuidv4()}>{address}</li>}
                {phone &&
                    <li className='item phone' key={uuidv4()}>{phone}</li>}
                {site &&
                    <li className='item site' key={uuidv4()}>
                        <a className='popup__link' href={site} key={uuidv4()}>
                            {site}
                            <img src={vector} alt="vector" key={uuidv4()}/>
                        </a>
                    </li>}
                {description &&
                    <li className='item description' key={uuidv4()}>{description}</li>}
            </ul>
        </div>
    )
}

const coachContent = ({id, icon, name, coachName}) => (
    <div key={uuidv4()}>
        <a href={`#user/${id}`} key={uuidv4()}>
            <ListItemIcon className={icon} key={uuidv4()}>
                <AboutFindCoachIcon
                    preserveAspectRatio="xMinYMid meet"
                    viewBox={"0 0 72 72"}
                    width={24}
                    height={24}
                    x={0}
                    y={0}
                    key={uuidv4()}
                />
            </ListItemIcon>
        </a>
        <CoachModal name={name} id={id} coachNameClass={coachName} key={uuidv4()}/>
    </div>
)

export const pickMarkerContent = ({type, id, icon, name, coachName, address, phone, site, description}) =>
    type === "LOCATIONS_MAP_TYPE"
        ? locationContent({name, address, phone, site, description})
        : coachContent({id, icon, name, coachName})
