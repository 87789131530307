import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

export const coachesSlice = createSlice({
    name: 'coaches',
    initialState: {
        coachesList: [],
        coachesListUpdated: 0
    },
    reducers: {
        loadCoaches: {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoachesList.fulfilled, (state, action) => {
                state.coachesList = action.payload
                state.coachesListUpdated++
            })
    },
})

export const {loadCoaches} = coachesSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const fetchCoachesList = createAsyncThunk('coaches/fetchCoachesList', async () => {
    const url = `${process.env.REACT_APP_FINDCOACH_URL}/findcoach/papi/user/coach/polygon`
    const response = await axios.get(url, {
        params: {
            coordinate: ['50.21,30.13', '50.64,30.13', '50.64,30.95', '50.21,30.95', '50.21,30.13'],
        },
        paramsSerializer: {
            indexes: null
        }
    });
    return response.data;
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCoachesList = (state) => state.coaches.coachesList
export default coachesSlice.reducer
