export const LINKS = {
  MAIN_APP: 'https://app.findcoach.com.ua/',
  SHOP_URL: 'https://shop.findcoach.com.ua/',
  PRIVACY_POLICY: '/privacy-policy-findcoach.html',
  TERMS_OF_SERVICE: '/terms-of-service.html',
}

export const REDUX = {
  GET_COACHES: 'GET_COACHES',
  GET_LOCATIONS: 'GET_LOCATIONS',
  SET_MAP_TYPE: 'SET_MAP_TYPE',
}

export const FILTERS = {
  LOCATIONS_FILTER: 'locations',
  COACHES_FILTER: 'coaches',
}

export const MAP_FILTER_TYPES = {
  LOCATIONS_MAP_TYPE: 'LOCATIONS_MAP_TYPE',
  COACHES_MAP_TYPE: 'COACHES_MAP_TYPE'
}