import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

export const locationSlice = createSlice({
    name: 'locations',
    initialState: {
        locationsList: [],
        locationsListUpdated: 0
    },
    reducers: {
        loadLocations: {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationsList.fulfilled, (state, action) => {
                state.locationsList = action.payload
                state.locationsListUpdated++
            })
    },
})

export const {loadLocations} = locationSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const fetchLocationsList = createAsyncThunk('locations/fetchLocationsList', async () => {
    const url = `${process.env.REACT_APP_FINDCOACH_URL}/findcoach/papi/location/polygon`;
    const response = await axios.get(url, {
        params: {
            coordinate: ['50.21,30.13', '50.64,30.13', '50.64,30.95', '50.21,30.95', '50.21,30.13'],
        },
        paramsSerializer: {
            indexes: null
        }
    })
    return response.data;
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectLocationsList = (state) => {
    return state.locationReducer.locationsList
}
export default locationSlice.reducer
