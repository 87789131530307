import React from 'react';
import './styles.scss'
import {Col, Container, Row} from "react-bootstrap";

export default function Contacts() {
    return (
        <div>
            <h1>Вітаємо на платформі ФайндКоач</h1>
            <p>Перелік наших сервісів:</p>
            <Container>
                <Row>
                    <Col sm={true}>
                        <h2>Крамниця</h2>
                        <p>Мета нашої крамниці &#8212; надати повний асортимент обладнення для тренувань, як персональних так і командних.</p>
                        <p>Ми працюємо над тим, щоб кожен атлет, кожен тренер, незалежно від виду спорту, зміг знайти в нашій крамниці все потрібне для покращення результатів.</p>
                    </Col>
                    <Col sm={true}>
                        <h2>Планувальник тренувань</h2>
                        <p>Планувальник допоможе якісно керувати програмами тренувань, збирати статистику, щоб покращити результати.</p>
                        <p><strong style={{textDecoration:"underline"}}>Планувальник наразі перебуває в Альфа</strong> версії і активно дороблюється. Це означає що можуть бути помилки або неочікувана поведінка.</p>
                    </Col>
                    <Col sm={true}>
                        <h2>Мапа для пошуку тренерів та локацій</h2>
                        <p>Атлетам допоможе знайти підходящу локацію для тренувань, тренера або спортивну подію щоб відвідати.</p>
                        <p>Тренерам допоможе прорекламувати себе та свої навички, а також знайти атлетів.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}