import React, {useEffect} from 'react';
import MapUI from './MapUI/MapUI'
import {denormalizeMarkers} from "../../../utils/markers";
import {connect} from 'react-redux';
import {MAP_FILTER_TYPES} from '../../../constants/constants';

const {LOCATIONS_MAP_TYPE} = MAP_FILTER_TYPES

function Map(props) {
    // const {GET_LOCATIONS} = REDUX
    const mapType = props.mapType;

    let localMarkers = [];

    const locations = props.locations;
    const coaches = props.coaches;
    localMarkers = mapType === LOCATIONS_MAP_TYPE ? locations : coaches
    let denormalizedLocalMarkers = denormalizeMarkers(localMarkers)

    useEffect(() => {
        const locations = props.locations;
        const coaches = props.coaches;
        // eslint-disable-next-line
        localMarkers = mapType === LOCATIONS_MAP_TYPE ? locations : coaches
        // eslint-disable-next-line
        denormalizedLocalMarkers = denormalizeMarkers(localMarkers)
    });


    return (
        <MapUI markers={denormalizedLocalMarkers} type={mapType} locationsListUpdated={props.locationsListUpdated}/>
    )
}

const mapStateToProps = function (state) {
    return {
        mapType: state.filterReducer.mapType,
        locations: state.locationReducer.locationsList,
        coaches: state.coachReducer.coachesList,
        locationsListUpdated: state.locationReducer.locationsListUpdated,
        coachesListUpdated: state.coachReducer.coachesListUpdated,
    }
}

export default connect(mapStateToProps)(Map);