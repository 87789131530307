import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import withStyles from "@mui/styles/withStyles";
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import {getMarkersList, pickMarkerContent} from "../../../../utils/markers";
import {v4 as uuidv4} from 'uuid';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconUrl: require('../../../../assets/icons/map/location_grey.svg').default,
    shadowSize: new L.Point(0, 0),
    iconSize: new L.Point(36, 56),
});

const styles = theme => ({
    mapStyles: {
        width: '100%',
        height: '80vh',
        [theme.breakpoints.down('sm')]: {
            height: '55vh',
        },
    },
    icon: {
        minWidth: '24px'
    },
    coachName: {
        position: 'relative',
        top: '-8px',
        left: '5px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline',
        margin: 0,
        padding: 0,
        '&:hover, &:focus': {
            textDecoration: 'none',
            outline: 'none',
        }


    }
});

function MapUI(props) {

    function getPopupContent(users, type, marker) {
        const {icon, coachName} = props.classes
        const {address, phone, site, description} = marker
        const result = []
        users.forEach((user) => {
            const {id, name} = user
            result.push(pickMarkerContent({type, id, name, icon, coachName, address, phone, site, description}))
        })
        return result
    }

    function setMarkers() {
        const {markers, type} = props
        const markersList = getMarkersList({markers})
        const result = []

        markersList.forEach((marker, index) => {
            const {x, y} = marker.coordinate
            const {users} = marker

            result.push(
                <Marker position={[x, y]} key={uuidv4()}>
                    <Popup key={uuidv4()}>
                        {getPopupContent(users, type, marker)}
                    </Popup>
                </Marker>
            )
        })
        return result
    }


    const {classes} = props;
    const position = [50.433576, 30.521434]
    return (
        <MapContainer center={position} zoom={14} zoomControl={true} className={classes.mapStyles}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                detectRetina={true}
                maxZoom={20}
                maxNativeZoom={17}
            />
            {setMarkers()}
        </MapContainer>
    );
}

export default withStyles(styles, {withTheme: true})(MapUI);