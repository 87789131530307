import React, {useState} from 'react'
import {
    AppBar,
    IconButton,
    Box,
    useMediaQuery
} from "@mui/material";
import clsx from "clsx";
import {Link} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {useStyles} from "./styles";
import {useTheme} from "@mui/styles";
import {ReactComponent as Logo} from './../../../assets/logo-white-red.svg';
import Typography from "@mui/material/Typography";
import {LINKS} from "../../../constants/constants";

export default function ApplicationBar () {
    const theme = useTheme();
    const classes = useStyles();
    const xs = useMediaQuery('(max-width: 400px)');
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const tabletMenuWidth = useMediaQuery('(min-width: 899px) and (max-width: 1000px)');

    const [open, setOpen] = useState(false);

    const onMenuToggle = () => {
        setOpen(!open);
    }

    return (
        <AppBar
          position="absolute"
          className={classes.appBar}
        >
            <Box sx={{ display: 'flex', alignSelf: 'start', height: '100%', alignItems: 'center', width: '100%'}}>
                <Link to="/">
                    <Logo
                        height="45px"
                        width={xs && 250}
                    />
                </Link>
                <Box sx={{flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
                    <IconButton
                         sx={{ border: open ? 'solid 1px #ffffff' : 'none', borderRadius: '5px'  }}
                         edge="start"
                         color="inherit"
                         onClick={onMenuToggle}
                         className={clsx(classes.menuButton, open && classes.openedMenu)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                {(open || matches) &&
                <Box
                     sx={{
                         top: {xs: '60px', md: '0px'},
                         position: {xs: 'absolute', md: 'static'},
                         left: 0,
                         right: 0,
                         width: '100%',
                         background: {xs: '#4c4c4c', md: 'inherit'},
                         display: 'flex',
                         paddingBottom: {xs: 4, md: 0},
                         justifyContent: {xs: 'end', md: 'start'},
                         flexDirection: { xs: 'column', md: 'row' }
                     }}
                     role="presentation"
                     onClick={onMenuToggle}
                >
                     <Typography align={'center'} mr={2} ml={2}>
                         <Link
                             to='/map'
                             className={tabletMenuWidth ? classes.tabletLink : classes.mobLink}
                         >
                             Пошук тренерів
                         </Link>
                     </Typography>
                     <Typography align={'center'} mr={2} ml={2}>
                         <Link
                             to={LINKS.SHOP_URL}
                             className={tabletMenuWidth ? classes.tabletLink : classes.mobLink}
                         >
                             Крамниця
                         </Link>
                     </Typography>
                     <Typography align={'center'} mr={2} ml={2}>
                         <Link
                             to={LINKS.MAIN_APP}
                             className={tabletMenuWidth ? classes.tabletLink : classes.mobLink}
                         >
                             Планувальник тренувань
                         </Link>
                     </Typography>
                    <Typography align={'center'} mr={2} ml={2}>
                        <Link
                            to='/contacts'
                            className={tabletMenuWidth ? classes.tabletLink : classes.mobLink}
                        >
                            Контакти
                        </Link>
                    </Typography>
                </Box>}
            </Box>
        </AppBar>
  )
}