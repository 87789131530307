import React, {useEffect, useState} from "react";
import axios from "axios";
import CoachUI from "./CoachUI/CoachUI";
import {withTranslation} from "react-i18next";

function Coach(props) {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        getUserData()
    });

    function getUserData() {
        const id = props.id ? props.id : +props.match.params.id;
        const url = `${process.env.REACT_APP_FINDCOACH_URL}/findcoach/papi/user/coach/info/${id}`;
        axios.get(url).then((response) => {
            setUserData(response.data);
        }).catch((error) => {
            console.log(error)
        });
    }

    return (
        <CoachUI userData={userData}/>
    )
}

export default (withTranslation()(Coach))
