import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./lang/i18n";
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {store, history} from './redux/configureStore';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BACKGROUND_PRIMARY} from './constants/common';

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
    palette: {
        primary: {
            main: BACKGROUND_PRIMARY,
        },
    },
    transitions: {
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    spacing: 4,
});

root.render(
    <Provider store={store}>
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Router>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
