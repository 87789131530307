import React from 'react';
import { makeStyles } from '@mui/styles';
// import Modal from '@material-ui/core/Modal';
import Modal from '@mui/material/Modal';
import Coach from "./Coach/Coach";
import {Grid} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    coachImage: {
        background: 'grey',
        width: '65px',
        minHeight: '65px',
    }
}));

export default function CoachModal({ name, id, coachNameClass }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <span>
            <button
                type="button"
                className={coachNameClass}
                onClick={() => handleOpen()}
            >
                { name }
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} >
                            <img
                                src={require("./../../assets/athlete.png")}
                                alt="Coach Avatar"
                                className={classes.coachImage}
                            />
                        </Grid>
                        <Grid item xs={9} >
                            <Coach id={id} />
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </span>
    );
}
