import React, {useEffect, useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {FILTERS} from "../../constants/constants";
import {fetchCoachesList} from '../../redux/coachSlice';
import {fetchLocationsList} from '../../redux/locationSlice';
import {setLocationsMap, setCoachesMap} from '../../redux/filterSlice';


const mapDispatchToProps = (dispatch) => {
    return {
        getLocations: () => {
            dispatch(fetchLocationsList())
        },
        getCoaches: () => {
            dispatch(fetchCoachesList())
        },
        setLocationsMap1: () => {
            dispatch(setLocationsMap())
        },
        setCoachesMap1: () => {
            dispatch(setCoachesMap())
        }
    }
}

const {LOCATIONS_FILTER, COACHES_FILTER} = FILTERS


function Filters(props) {
    const [filterType, setFilterType] = useState(LOCATIONS_FILTER);

    useEffect(() => {
        // TODO: this method makes this call for every re-rendering - need to avoid it
        props.getLocations()
    });

    function handleRadioChange(type) {
        setFilterType(type)
    };

    function handleSubmit(event) {
        event.preventDefault();
    };

    function handleLocations() {
        handleRadioChange(LOCATIONS_FILTER)
        props.setLocationsMap1();
        props.getLocations()
    }

    function handleCoaches() {
        handleRadioChange(COACHES_FILTER)
        props.setCoachesMap1();
        props.getCoaches()
    }

    const {t} = props
    // TODO: clicking on the text of the radio button triggers loading twice
    return (
        <form onSubmit={() => handleSubmit()}>
            <Box p={3}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="quiz" name="quiz" value={filterType}>
                        <FormControlLabel
                            value={LOCATIONS_FILTER}
                            control={<Radio color="primary"/>}
                            label={t('filters.locations')}
                            onClick={() => handleLocations()}
                        />
                        <FormControlLabel
                            value={COACHES_FILTER}
                            control={<Radio color="primary"/>}
                            label={t('filters.coaches')}
                            onClick={() => handleCoaches()}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </form>
    );
}

const mapStateToProps = function (state) {
    return {
        map: state.mapType,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filters))
