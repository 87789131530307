import {REDUX} from "../../constants/constants";

const { SET_MAP_TYPE, GET_LOCATIONS } = REDUX

const map = (state = { type: GET_LOCATIONS }, action) => {
    switch (action.type) {
        case SET_MAP_TYPE:
            const type = { type: action.payload }
            return {
                ...state,
                ...type,
            }
        default:
            return state
    }
}

export default map